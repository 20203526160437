* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: #2a262c;
    color: #fff;
    font-family: "Signika Negative", sans-serif;
    font-size: 16px;
}

a {
    text-decoration: none;
    color: unset;
}


.input-label {
    color: #6C7293;
}